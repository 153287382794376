import styles from "./InputText.module.scss";

import * as React from "react";
import { Field } from "formik";

import { EntityInputText } from "@lib/entities/EntityInputText";
import InputError from "../input-error/InputError";

interface IInputText extends EntityInputText {
  isError: boolean;
  isTouched: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const InputText = (props: IInputText) => {
  const [value, setValue] = React.useState<string>("");

  function handleChange(e: any) {
    props.setFieldValue(props.id, e.target.value);
    setValue(e.target.value);
  }

  const classNameWrapper = props.width === "50%" ? styles.wrapperNarrow : styles.wrapper;
  const className = props.isError && props.isTouched ? styles.inputError : styles.input;

  const classNameLabel = value ? styles.labelFilled : styles.label;

  return (
    <div className={classNameWrapper}>
      <Field
        className={className}
        type={props.inputType}
        name={props.id}
        value={undefined} // https://stackoverflow.com/questions/66876022/setfieldvalue-formik-and-invalidstateerror-failed-to-set-the-value-property
        onChange={handleChange}
      />
      <label className={classNameLabel}>{props.placeholder}</label>
      {props.isError && props.isTouched ? <InputError>{props.error}</InputError> : null}
    </div>
  );
};

export default InputText;
