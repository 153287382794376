import { IPage } from "../types/IPage";

import { TBlocks } from "../types/TBlocks";
import { SeoOrFaviconTag } from "react-datocms";

import { mapBlocksToEntities } from "./mapBlocksToEntities";

export class EntityPage implements IPage {
  constructor(
    public seo: SeoOrFaviconTag[],
    public id: string,
    public slug: string,
    public isNavigationInverted: boolean,
    public documentName: string,
    public blocks: TBlocks
  ) {}

  static createFromObject(parameters: IPage): EntityPage {
    return new EntityPage(
      parameters.seo,
      parameters.id,
      parameters.slug,
      parameters.isNavigationInverted,
      parameters.documentName,
      mapBlocksToEntities(parameters.blocks)
    );
  }
}
