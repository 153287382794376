import { IOurProcessItem } from "@lib/types/IOurProcessItem";
import { IOurProcessListItem } from "@lib/types/IOurProcessListItem";
import { IResponsiveImage } from "@lib/types/IResponsiveImage";

export class EntityOurProcessItem implements IOurProcessItem {
  constructor(
    public _modelApiKey: "our_process_item",
    public id: string,
    public heading: string,
    public paragraph: string,
    public image: IResponsiveImage,
    public items: IOurProcessListItem[]
  ) {}

  static createFromObject(parameters: IOurProcessItem): EntityOurProcessItem {
    return new EntityOurProcessItem(
      parameters._modelApiKey,
      parameters.id,
      parameters.heading,
      parameters.paragraph,
      parameters.image,
      parameters.items
    );
  }
}
