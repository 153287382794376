import * as React from "react";

export enum MODAL_TYPES {
  HIDDEN = "HIDDEN",
  OPEN_APPLICATION = "OPEN_APPLICATION"
}

export interface IStoreModal {
  status: MODAL_TYPES;
  open: (type: MODAL_TYPES) => void;
  close: () => void;
}

const defaultValues = {
  status: MODAL_TYPES.HIDDEN,
  open: (type: MODAL_TYPES) => {},
  close: () => {}
};

const StoreModal = React.createContext<IStoreModal>(defaultValues);

interface IStoreModalProvider {
  children?: React.ReactNode;
}

export const StoreModalProvider = (props: IStoreModalProvider) => {
  const [status, setStatus] = React.useState(defaultValues.status);

  return (
    <StoreModal.Provider
      value={{
        status,
        open: (type: MODAL_TYPES) => {
          setStatus(type);
        },
        close: () => {
          setStatus(MODAL_TYPES.HIDDEN);
        }
      }}
    >
      {props.children}
    </StoreModal.Provider>
  );
};

export default StoreModal;
