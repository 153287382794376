import styles from "./ButtonMenu.module.scss";

import * as React from "react";
import Button, { TButton } from "@components/buttons/Button";
import { useRouter } from "next/router";

const ButtonMenu = (props: TButton) => {
  const router = useRouter();
  const { query } = router;
  const slug = query.slug ? query.slug[0] : undefined;

  const { children, ...rest } = props;

  const className =
    slug && props?.page?.slug
      ? props.page.slug.indexOf(slug) > -1
        ? styles.buttonActive
        : styles.button
      : styles.button;

  return (
    <Button className={className} {...rest}>
      {children}
    </Button>
  );
};

export default ButtonMenu;
