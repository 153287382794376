import { IInputSelect } from "../types/IInputSelect";

export class EntityInputSelect implements IInputSelect {
  constructor(
    public _modelApiKey: "input_select",
    public id: string,
    public name: string,
    public placeholder: string,
    public options: string,
    public error: string,
    public isRequired: boolean
  ) {}

  static createFromObject(parameters: IInputSelect): EntityInputSelect {
    return new EntityInputSelect(
      parameters._modelApiKey,
      parameters.id,
      parameters.name,
      parameters.placeholder,
      parameters.options,
      parameters.error,
      parameters.isRequired
    );
  }

  getOptions = () => {
    return this.options.split("\n");
  };

  getFirstOption = () => {
    return this.getOptions()[0];
  };

  validate = (value: string) => {
    if (!value && !this.isRequired) {
      return {
        key: this.id,
        value: null
      };
    }

    // required
    if (!value) {
      return {
        key: this.id,
        value: "Required value"
      };
    }

    // valid
    return {
      key: this.id,
      value: null
    };
  };
}
