import { IResponsiveImage } from "@lib/types/IResponsiveImage";
import { IImage } from "../types/IImage";

export class EntityImage implements IImage {
  constructor(public _modelApiKey: "image", public id: string, public image: IResponsiveImage) {}

  static createFromObject(parameters: IImage): EntityImage {
    return new EntityImage(parameters._modelApiKey, parameters.id, parameters.image);
  }
}
