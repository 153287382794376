import { IReference } from "@lib/types/IReference";
import { IBlockTestimonial } from "../types/IBlockTestimonial";

export class EntityBlockTestimonial implements IBlockTestimonial {
  constructor(
    public _modelApiKey: "testimonial",
    public id: string,
    public eyebrow: string,
    public heading: string,
    public references: IReference[],
    public isInverted: boolean,
    public isVariantShort: boolean,
    public useAllReferences: boolean,
    public autoplay: boolean
  ) {}

  static createFromObject(parameters: IBlockTestimonial): EntityBlockTestimonial {
    return new EntityBlockTestimonial(
      parameters._modelApiKey,
      parameters.id,
      parameters.eyebrow,
      parameters.heading,
      parameters.references,
      parameters.isInverted,
      parameters.isVariantShort,
      parameters.useAllReferences,
      parameters.autoplay
    );
  }
}
