import { EntityInputCheckbox } from "@lib/entities/EntityInputCheckbox";
import { EntityInputSelect } from "@lib/entities/EntityInputSelect";
import { EntityInputText } from "@lib/entities/EntityInputText";
import { EntityInputUploadCv } from "@lib/entities/EntityInputUploadCv";
import { INPUT_FIELDS } from "@lib/enums/inputFields";
import InputCheckbox from "./input-checkbox/InputCheckbox";
import InputSelect from "./input-select/InputSelect";
import InputText from "./input-text/InputText";
import InputUploadCv from "./input-upload-cv/InputUploadCv";
import { FormikTouched, FormikErrors } from "formik";

function assertUnreachable(modelApiKey: string): never {
  throw new Error(`Unknown Input '${modelApiKey}'`);
}

interface IValues {
  inputField: EntityInputText | EntityInputCheckbox | EntityInputSelect | EntityInputUploadCv;
  isError: boolean;
  isTouched: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

function getInputField(values: IValues) {
  const { inputField, isError, isTouched, setFieldValue } = values;

  switch (inputField._modelApiKey) {
    case INPUT_FIELDS.InputText: {
      return (
        <InputText
          key={inputField.id}
          {...inputField}
          isError={isError}
          isTouched={isTouched}
          setFieldValue={setFieldValue}
        />
      );
    }
    case INPUT_FIELDS.InputCheckbox: {
      return (
        <InputCheckbox
          key={inputField.id}
          {...inputField}
          isError={isError}
          isTouched={isTouched}
        />
      );
    }
    case INPUT_FIELDS.InputSelect: {
      return (
        <InputSelect
          key={inputField.id}
          {...inputField}
          isError={isError}
          isTouched={isTouched}
          setFieldValue={setFieldValue}
        />
      );
    }
    case INPUT_FIELDS.InputUploadCv: {
      return (
        <InputUploadCv
          key={inputField.id}
          {...inputField}
          isError={isError}
          isTouched={isTouched}
          setFieldValue={setFieldValue}
        />
      );
    }
    default:
      assertUnreachable(inputField._modelApiKey);
  }
}

interface AllInputFieldsProps {
  inputFields: Array<
    EntityInputText | EntityInputCheckbox | EntityInputSelect | EntityInputUploadCv
  >;
  errors: FormikErrors<{}>;
  touched: FormikTouched<{}>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const AllInputFields = ({ inputFields, errors, touched, setFieldValue }: AllInputFieldsProps) => {
  return (
    <>
      {inputFields.map((inputField) => {
        const error = Object.entries(errors).find(([key, _]) => key === inputField.id);
        const isError = error ? !!error[1] : false;

        const touch = Object.entries(touched).find(([key, _]) => key === inputField.id);
        const isTouched = touch ? !!touch[1] : false;

        return getInputField({ inputField, isError, isTouched, setFieldValue });
      })}
    </>
  );
};

export default AllInputFields;
