import styles from "./Form.module.scss";

import * as React from "react";
import { Formik, Form as FormikForm } from "formik";

import { cx } from "helpers/classNames";
import AllInputFields from "@components/inputs/Inputs";
import ButtonPrimary, {
  BUTTON_PRIMARY_ARROW,
  BUTTON_PRIMARY_VARIANTS
} from "@components/buttons/ButtonPrimary";
import HeadingSmall from "@components/heading-small/HeadingSmall";

import { handleSubmit } from "./handlers";
import { getInitialFormValues, validate } from "./helpers";
import { EntityForm } from "@lib/entities/EntityForm";
import RichText from "@components/rich-text/RichText";
import { EntityGlobal } from "@lib/entities/EntityGlobal";
import { EntityJob } from "@lib/entities/EntityJob";

export type TForm = {
  global: EntityGlobal;
  form: EntityForm;
  className?: string;
  modal?: boolean;
  job?: EntityJob;
};

export enum FORM_STATUS {
  Init,
  Error,
  Send
}

function onlyUnique(value: string, index: number, self: string[]) {
  return self.indexOf(value) === index;
}

const MyForm = (props: TForm) => {
  const [status, setStatus] = React.useState<FORM_STATUS>(FORM_STATUS.Init);
  const { form, global } = props;

  const className = props.modal ? styles.formModal : styles.form;

  let mailTo = [];
  if (props.job?.member?.email) mailTo.push(props.job?.member?.email);
  if (!mailTo.length) {
    if (form.mailTo) mailTo.push(form.mailTo);
  }
  if (!mailTo.length) {
    mailTo.push(global.email);
  }
  mailTo = mailTo.filter(onlyUnique);

  if (status === FORM_STATUS.Send) {
    return (
      <div className={className}>
        <div className={styles.heading}>
          <HeadingSmall>{props.form.messageSuccessHeading}</HeadingSmall>
        </div>
        <div className={styles.paragraph}>
          <RichText richtext={props.form.messageSuccessParagraph} />
        </div>
        <ButtonPrimary
          arrow={BUTTON_PRIMARY_ARROW.Right}
          variant={BUTTON_PRIMARY_VARIANTS.FilledOnPurple}
          onClick={() => {
            setStatus(FORM_STATUS.Init);
          }}
        >
          Back
        </ButtonPrimary>
      </div>
    );
  }

  return (
    <Formik
      onSubmit={handleSubmit(
        props.form.mailSubject,
        mailTo,
        form.inputFields,
        setStatus,
        props.job
      )}
      initialValues={getInitialFormValues(form.getAllFields())}
      validate={validate(form.getAllFields())}
      className={cx(styles.container, props.className)}
    >
      {({ errors, touched, isSubmitting, setFieldValue }) => (
        <FormikForm className={className}>
          <div className={styles.heading}>
            <HeadingSmall>{form.heading}</HeadingSmall>
          </div>

          <div className={styles.inputFields}>
            <AllInputFields
              inputFields={form.inputFields}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
            />
          </div>

          <div className={styles.footer}>
            <div>
              <AllInputFields
                inputFields={form.terms}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
              />
            </div>
            <ButtonPrimary
              arrow={BUTTON_PRIMARY_ARROW.Right}
              variant={BUTTON_PRIMARY_VARIANTS.FilledOnPurple}
              disabled={isSubmitting}
              type="submit"
            >
              {form.labelButtonSubmit}
            </ButtonPrimary>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default MyForm;
