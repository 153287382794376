import { IBlockOurProcess } from "../types/IBlockOurProcess";
import { EntityOurProcessItem } from "./EntityOurProcessItem";

export class EntityBlockOurProcess implements IBlockOurProcess {
  constructor(
    public _modelApiKey: "our_process",
    public id: string,
    public eyebrow: string,
    public heading: string,
    public paragraph: string,
    public items: EntityOurProcessItem[]
  ) {}

  static createFromObject(parameters: IBlockOurProcess): EntityBlockOurProcess {
    return new EntityBlockOurProcess(
      parameters._modelApiKey,
      parameters.id,
      parameters.eyebrow,
      parameters.heading,
      parameters.paragraph,
      parameters.items.map((item) => {
        return EntityOurProcessItem.createFromObject(item);
      })
    );
  }
}
