// FIXME: store values and reuse

import { useState, useEffect } from "react";
// Define general type for useOrientationChange hook, which includes width and height
interface Size {
  width: number | undefined;
  height: number | undefined;
  documentFontSize: number | undefined;
}
// Usage
// function App() {
//   const size: Size = useOrientationChange();
//   return (
//     <div>
//       {size.width}px / {size.height}px
//     </div>
//   );
// }
// Hook

const RESIZE_INTERVAL = 250;

function useOrientationChange(): Size {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
    documentFontSize: undefined
  });
  useEffect(() => {
    const resizeEvent = "onorientationchange" in window ? "orientationchange" : "resize";

    let throttleTimer: ReturnType<typeof setTimeout>;

    function updateValues() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        documentFontSize: parseFloat(
          getComputedStyle(document.documentElement).getPropertyValue("font-size")
        )
      });
    }
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      updateValues();

      if (throttleTimer) clearTimeout(throttleTimer);
      throttleTimer = setTimeout(updateValues, RESIZE_INTERVAL);
    }
    // Add event listener
    window.addEventListener(resizeEvent, handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener(resizeEvent, handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export default useOrientationChange;
