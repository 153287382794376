import styles from "./HeadingSmall.module.scss";

import * as React from "react";
import { cx } from "helpers/classNames";

interface IHeadingSmall {
  children: React.ReactNode;
  className?: string;
  as?: string;
}

const HeadingSmall = (props: IHeadingSmall) => {
  const Tag = (props.as || "h2") as keyof JSX.IntrinsicElements;
  const className = cx(props.className, styles.text);
  return <Tag className={className}>{props.children}</Tag>;
};

export default HeadingSmall;
