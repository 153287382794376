import styles from "./InputError.module.scss";

import * as React from "react";

interface IInputError {
  children?: React.ReactNode;
}

const InputError = (props: IInputError) => {
  return <div className={styles.wrapper}>{props.children}</div>;
};

export default InputError;
