import { ASSETS } from "@lib/enums/assets";
import { IResponsiveImage } from "@lib/types/IResponsiveImage";
import { notEmpty } from "helpers/notEmpty";
import { IBlockTextImage } from "../types/IBlockTextImage";
import { EntityImage } from "./EntityImage";
import { EntityMap } from "./EntityMap";

export class EntityBlockTextImage implements IBlockTextImage {
  constructor(
    public _modelApiKey: "text_image",
    public id: string,
    public eyebrow: string,
    public paragraph: string,
    public indent: boolean,
    public assets: (EntityImage | EntityMap)[]
  ) {}

  static createFromObject(parameters: IBlockTextImage): EntityBlockTextImage {
    const assets = parameters.assets
      .map((item) => {
        switch (item._modelApiKey) {
          case ASSETS.Image: {
            return EntityImage.createFromObject(item);
          }
          case ASSETS.Map: {
            return EntityMap.createFromObject(item);
          }
          default: {
            return undefined;
          }
        }
      })
      .filter(notEmpty);

    return new EntityBlockTextImage(
      parameters._modelApiKey,
      parameters.id,
      parameters.eyebrow,
      parameters.paragraph,
      parameters.indent,
      assets
    );
  }
}
