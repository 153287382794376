import { IBlockCompaniesTypeA } from "../types/IBlockCompaniesTypeA";
import { ICompany } from "../types/ICompany";
import { EntityCompany } from "./EntityCompany";

export class EntityBlockCompaniesTypeA implements IBlockCompaniesTypeA {
  constructor(
    public _modelApiKey: "companies_type_a",
    public id: string,
    public eyebrow: string,
    public companies: Array<ICompany>
  ) {}

  static createFromObject(parameters: IBlockCompaniesTypeA): EntityBlockCompaniesTypeA {
    return new EntityBlockCompaniesTypeA(
      parameters._modelApiKey,
      parameters.id,
      parameters.eyebrow,
      parameters.companies.map((company) => {
        return EntityCompany.createFromObject(company);
      })
    );
  }
}
