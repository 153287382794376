import { IBlockJobsAll } from "../types/IBlockJobsAll";

export class EntityBlockJobsAll implements IBlockJobsAll {
  constructor(
    public _modelApiKey: "jobs_all",
    public id: string,
    public heading: string,
    public defaultSize: number
  ) {}

  static createFromObject(parameters: IBlockJobsAll): EntityBlockJobsAll {
    return new EntityBlockJobsAll(
      parameters._modelApiKey,
      parameters.id,
      parameters.heading,
      parameters.defaultSize
    );
  }
}
