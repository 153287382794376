import { IInputText } from "../types/IInputText";
import validate from "validate.js";

export class EntityInputText implements IInputText {
  constructor(
    public _modelApiKey: "input_text",
    public id: string,
    public name: string,
    public placeholder: string,
    public width: string,
    public error: string,
    public isRequired: boolean,
    public inputType: "text" | "email" | "phone"
  ) {}

  static createFromObject(parameters: IInputText): EntityInputText {
    return new EntityInputText(
      parameters._modelApiKey,
      parameters.id,
      parameters.name,
      parameters.placeholder,
      parameters.width,
      parameters.error,
      parameters.isRequired,
      parameters.inputType
    );
  }

  validate = (value: string) => {
    if (!value && !this.isRequired) {
      return {
        key: this.id,
        value: null
      };
    }

    // required
    if (this.inputType === "text") {
      if (
        validate.single(value, {
          presence: {
            allowEmpty: false
          }
        })
      ) {
        return {
          key: this.id,
          value: "INVALID"
        };
      }
    }

    if (this.inputType === "phone") {
      if (
        validate.single(value, {
          presence: {
            allowEmpty: false
          }
        })
      ) {
        return {
          key: this.id,
          value: "INVALID"
        };
      }
    }

    if (this.inputType === "email") {
      if (
        validate.single(value, {
          presence: {
            allowEmpty: false
          },
          email: true
        })
      ) {
        return {
          key: this.id,
          value: "INVALID"
        };
      }
    }

    // valid
    return {
      key: this.id,
      value: null
    };
  };
}
