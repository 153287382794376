import { ASSETS } from "@lib/enums/assets";
import { IBlockContactMapAndForm } from "@lib/types/IBlockContactMapAndForm";
import { notEmpty } from "helpers/notEmpty";
import { EntityForm } from "./EntityForm";
import { EntityImage } from "./EntityImage";
import { EntityMap } from "./EntityMap";

export class EntityBlockContactMapAndForm implements IBlockContactMapAndForm {
  constructor(
    public _modelApiKey: "contact_map_and_form",
    public id: string,
    public form: EntityForm[],
    public assets: (EntityImage | EntityMap)[]
  ) {}

  static createFromObject(parameters: IBlockContactMapAndForm): EntityBlockContactMapAndForm {
    const assets = parameters.assets
      .map((item) => {
        switch (item._modelApiKey) {
          case ASSETS.Image: {
            return EntityImage.createFromObject(item);
          }
          case ASSETS.Map: {
            return EntityMap.createFromObject(item);
          }
          default: {
            return undefined;
          }
        }
      })
      .filter(notEmpty);

    return new EntityBlockContactMapAndForm(
      parameters._modelApiKey,
      parameters.id,
      parameters.form.map((form) => {
        return EntityForm.createFromObject(form);
      }),
      assets
    );
  }
}
