import { IJob } from "../types/IJob";

import { SeoOrFaviconTag } from "react-datocms";
import { IJobContent } from "@lib/types/IJobContent";

import { EntityMember } from "./EntityMember";
import { IReference } from "@lib/types/IReference";
import { EntityJobTag } from "./EntityJobTag";
import { EntityJobTagGroup } from "./EntityJobTagGroup";

export class EntityJob implements IJob {
  constructor(
    public seo: SeoOrFaviconTag[],
    public id: string,
    public title: string,
    public introduction: string,
    public content: IJobContent[],
    public jobTags: EntityJobTag[],
    public linkedin: string,
    public slug: string,
    public member: EntityMember | undefined,
    public references: IReference[]
  ) {}

  static createFromObject(parameters: IJob): EntityJob {
    return new EntityJob(
      parameters.seo,
      parameters.id,
      parameters.title,
      parameters.introduction,
      parameters.content,
      parameters.jobTags.map((jobTag) => {
        return EntityJobTag.createFromObject(jobTag);
      }),
      parameters.linkedin,
      parameters.slug,
      parameters.member ? EntityMember.createFromObject(parameters.member) : undefined,
      parameters.references
    );
  }

  getPage = () => {
    return {
      slug: `job/${this.slug}`
    };
  };

  getTags = () => {
    return this.jobTags;
  };

  getTagsInTagGroups = (groups: EntityJobTagGroup[]) => {
    return this.jobTags.filter((tag) => {
      let shouldInclude = false;

      groups.forEach((group) => {
        if (tag.tagGroup.id === group.id) {
          shouldInclude = true;
        }
      });

      return shouldInclude;
    });
  };
}
