import { useRouter } from "next/router";
import * as React from "react";

interface ITransitionData {
  slug: string;
  previousSlug?: string;
}

export interface IStoreTransitionData {
  slug?: string;
  previousSlug?: string;
}

const defaultValues = {};

const StoreTransitionData = React.createContext<IStoreTransitionData>(defaultValues);

interface ITransitionDataProvider {
  children?: React.ReactNode;
}

const TransitionDataProvider = (props: ITransitionDataProvider) => {
  const router = useRouter();
  const cleanUrl = router.asPath.replace(/^\/|\/$/g, "");

  const [transitionData, setTransitionData] = React.useState<ITransitionData>({ slug: cleanUrl });

  React.useEffect(() => {
    const handleRouteChange = (url: string) => {
      const cleanUrl = url.replace(/^\/|\/$/g, "");
      if (transitionData.slug !== cleanUrl) {
        const newTransitionData = { slug: cleanUrl, previousSlug: transitionData.slug };
        setTransitionData(newTransitionData);
      }
    };

    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router, transitionData]);

  return (
    <StoreTransitionData.Provider value={transitionData}>
      {props.children}
    </StoreTransitionData.Provider>
  );
};

export const useTransitionData = () => {
  const transitionData = React.useContext(StoreTransitionData);
  return transitionData;
};

export default TransitionDataProvider;
