import { IGlobal } from "../types/IGlobal";
import { EntityForm } from "./EntityForm";
import { EntityJobTagGroup } from "./EntityJobTagGroup";

export class EntityGlobal implements IGlobal {
  constructor(
    public companyName: string,
    public address: string,
    public zipcode: string,
    public city: string,
    public phone: string,
    public email: string,
    public jobsListingPage: { slug: string },
    public tagGroupsColumnOne: EntityJobTagGroup[],
    public tagGroupsColumnTwo: EntityJobTagGroup[],
    public cookieConsentParagraph: string,
    public cookieConsentButtonConsent: string,
    public cookieConsentButtonDenial: string,
    public buttonLinkedinLabel: string,
    public buttonApplyLabel: string,
    public headingContact: string,
    public jobDetailForm: EntityForm[],
    public openApplicationForm: EntityForm[],
    public pageNotFoundHeading: string,
    public pageNotFoundParagraph: string
  ) {}

  static createFromObject(parameters: IGlobal): EntityGlobal {
    return new EntityGlobal(
      parameters.companyName,
      parameters.address,
      parameters.zipcode,
      parameters.city,
      parameters.phone,
      parameters.email,
      parameters.jobsListingPage,
      parameters.tagGroupsColumnOne
        ? parameters.tagGroupsColumnOne.map((tagGroup) => {
            return EntityJobTagGroup.createFromObject(tagGroup);
          })
        : [],
      parameters.tagGroupsColumnTwo
        ? parameters.tagGroupsColumnTwo.map((tagGroup) => {
            return EntityJobTagGroup.createFromObject(tagGroup);
          })
        : [],
      parameters.cookieConsentParagraph,
      parameters.cookieConsentButtonConsent,
      parameters.cookieConsentButtonDenial,
      parameters.buttonLinkedinLabel,
      parameters.buttonApplyLabel,
      parameters.headingContact,
      parameters.jobDetailForm
        ? parameters.jobDetailForm.map((form) => {
            return EntityForm.createFromObject(form);
          })
        : [],
      parameters.openApplicationForm
        ? parameters.openApplicationForm.map((form) => {
            return EntityForm.createFromObject(form);
          })
        : [],
      parameters.pageNotFoundHeading,
      parameters.pageNotFoundParagraph
    );
  }
}
