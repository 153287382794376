import { INavigation } from "@lib/types/INavigation";

import { EntityButtonExternal } from "./EntityButtonExternal";
import { EntityButtonInternal } from "./EntityButtonInternal";

import { BUTTONS } from "../enums/buttons";

import { notEmpty } from "helpers/notEmpty";

export class EntityNavigation implements INavigation {
  constructor(public menu: Array<EntityButtonInternal | EntityButtonExternal>) {}

  static createFromObject(parameters: INavigation): EntityNavigation {
    const menu = parameters.menu
      ? parameters.menu
          .map((button) => {
            switch (button._modelApiKey) {
              case BUTTONS.ButtonInternal: {
                return EntityButtonInternal.createFromObject(button);
              }
              case BUTTONS.ButtonExternal: {
                return EntityButtonExternal.createFromObject(button);
              }
              default: {
                return undefined;
              }
            }
          })
          .filter(notEmpty)
      : [];

    return new EntityNavigation(menu);
  }
}
