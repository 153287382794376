import { IJobTag } from "../types/IJobTag";
import { EntityJobTagGroup } from "./EntityJobTagGroup";

export class EntityJobTag implements IJobTag {
  constructor(
    public id: string,
    public title: string,
    public slug: string,
    public tagGroup: EntityJobTagGroup
  ) {}

  static createFromObject(parameters: IJobTag): EntityJobTag {
    return new EntityJobTag(
      parameters.id,
      parameters.title,
      parameters.slug,
      EntityJobTagGroup.createFromObject(parameters.tagGroup)
    );
  }
}
