import { IBlockHowWeWork } from "../types/IBlockHowWeWork";
import { EntityHowWeWorkItem } from "./EntityHowWeWorkItem";

export class EntityBlockHowWeWork implements IBlockHowWeWork {
  constructor(
    public _modelApiKey: "how_we_work",
    public id: string,
    public eyebrow: string,
    public paragraph: string,
    public items: EntityHowWeWorkItem[]
  ) {}

  static createFromObject(parameters: IBlockHowWeWork): EntityBlockHowWeWork {
    return new EntityBlockHowWeWork(
      parameters._modelApiKey,
      parameters.id,
      parameters.eyebrow,
      parameters.paragraph,
      parameters.items.map((item) => {
        return EntityHowWeWorkItem.createFromObject(item);
      })
    );
  }
}
