import { IInputCheckbox } from "../types/IInputCheckbox";

export class EntityInputCheckbox implements IInputCheckbox {
  constructor(
    public _modelApiKey: "input_checkbox",
    public id: string,
    public name: string,
    public label: string,
    public error: string,
    public isPreselected: boolean,
    public isRequired: boolean
  ) {}

  static createFromObject(parameters: IInputCheckbox): EntityInputCheckbox {
    return new EntityInputCheckbox(
      parameters._modelApiKey,
      parameters.id,
      parameters.name,
      parameters.label,
      parameters.error,
      parameters.isPreselected,
      parameters.isRequired
    );
  }

  validate = (value: string) => {
    if (!value && !this.isRequired) {
      return {
        key: this.id,
        value: null
      };
    }

    // required
    if (!value) {
      return {
        key: this.id,
        value: "Required value"
      };
    }

    // valid
    return {
      key: this.id,
      value: null
    };
  };
}
