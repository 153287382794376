import { IBlockHeaderTypeA } from "../types/IBlockHeaderTypeA";

import { EntityButtonHeaderTypeAInternal } from "./EntityButtonHeaderTypeAInternal";
import { EntityButtonHeaderTypeAExternal } from "./EntityButtonHeaderTypeAExternal";

import { notEmpty } from "helpers/notEmpty";
import { BUTTONS } from "@lib/enums/buttons";

export class EntityBlockHeaderTypeA implements IBlockHeaderTypeA {
  constructor(
    public _modelApiKey: "header_type_a",
    public id: string,
    public intro: string,
    public payoff: string,
    public buttons: (EntityButtonHeaderTypeAInternal | EntityButtonHeaderTypeAExternal)[]
  ) {}

  static createFromObject(parameters: IBlockHeaderTypeA): EntityBlockHeaderTypeA {
    const buttons = parameters.buttons
      .map((button) => {
        switch (button._modelApiKey) {
          case BUTTONS.ButtonHeaderTypeAInternal: {
            return EntityButtonHeaderTypeAInternal.createFromObject(button);
          }
          case BUTTONS.ButtonHeaderTypeAExternal: {
            return EntityButtonHeaderTypeAExternal.createFromObject(button);
          }
          default: {
            return undefined;
          }
        }
      })
      .filter(notEmpty);

    return new EntityBlockHeaderTypeA(
      parameters._modelApiKey,
      parameters.id,
      parameters.intro,
      parameters.payoff,
      buttons
    );
  }
}
