import styles from "./Modal.module.scss";

import * as React from "react";
import StoreModal, { MODAL_TYPES } from "@stores/StoreModal";
import { EntityGlobal } from "@lib/entities/EntityGlobal";
import ModalForm from "./ModalForm";

interface IModalProps {
  global: EntityGlobal;
}

const Modal = (props: IModalProps) => {
  const storeModal = React.useContext(StoreModal);

  if (storeModal.status === MODAL_TYPES.HIDDEN) {
    return null;
  }

  if (!Object.values(MODAL_TYPES).includes(storeModal.status)) {
    console.error(`Undefined Modal: ${storeModal.status}`);
    return null;
  }

  return (
    <div className={styles.container}>
      {storeModal.status === MODAL_TYPES.OPEN_APPLICATION && (
        <>
          {props.global.openApplicationForm.map((form) => {
            return <ModalForm key={form.id} form={form} global={props.global} />;
          })}
        </>
      )}
    </div>
  );
};

export default Modal;
