import { INPUT_FIELDS } from "@lib/enums/inputFields";
import { EntityInputSelect } from "@lib/entities/EntityInputSelect";
import { EntityInputText } from "@lib/entities/EntityInputText";
import { EntityInputUploadCv } from "@lib/entities/EntityInputUploadCv";
import { FormikValues } from "formik";
import { EntityInputCheckbox } from "@lib/entities/EntityInputCheckbox";

export function getInitialFormValues(
  inputFields: Array<
    EntityInputText | EntityInputCheckbox | EntityInputSelect | EntityInputUploadCv
  >
) {
  const values = inputFields
    .map((inputField) => {
      switch (inputField._modelApiKey) {
        case INPUT_FIELDS.InputText: {
          return { key: inputField.id, value: "" };
        }
        case INPUT_FIELDS.InputCheckbox: {
          return { key: inputField.id, value: inputField.isPreselected };
        }
        case INPUT_FIELDS.InputSelect: {
          return { key: inputField.id, value: "" };
        }
        case INPUT_FIELDS.InputUploadCv: {
          return { key: inputField.id, value: "" };
        }
        default:
          return null;
      }
    })
    .filter((item) => item)
    .reduce((a, v) => (v ? { ...a, [v.key]: v.value } : {}), {});

  return values;
}

export const validate =
  (
    inputFields: Array<
      EntityInputText | EntityInputCheckbox | EntityInputSelect | EntityInputUploadCv
    >
  ) =>
  (values: FormikValues) => {
    // return [];
    const errors = inputFields
      .map((inputField) => {
        const item = Object.entries(values).find(([key, value]) => key === inputField.id);
        const value = item ? item[1] : null;
        return inputField.validate(value);
      })
      .filter((item) => item.value)
      .reduce((a, v) => (v ? { ...a, [v.key]: v.value } : {}), {});

    return errors;
  };
