import { INPUT_FIELDS } from "../enums/inputFields";
import { IInputCheckbox } from "@lib/types/IInputCheckbox";
import { EntityInputCheckbox } from "./EntityInputCheckbox";

function assertUnreachable(modelApiKey: string): never {
  throw new Error(`Unknown block '${modelApiKey}'`);
}

export const mapTermsToEntities = (inputFields: Array<IInputCheckbox>) => {
  return inputFields.map((inputField) => {
    switch (inputField._modelApiKey) {
      case INPUT_FIELDS.InputCheckbox: {
        return EntityInputCheckbox.createFromObject(inputField);
      }
      default:
        assertUnreachable(inputField._modelApiKey);
    }
  });
};
