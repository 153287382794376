import { IBlockMeetTheTeam } from "../types/IBlockMeetTheTeam";
import { EntityMember } from "./EntityMember";

export class EntityBlockMeetTheTeam implements IBlockMeetTheTeam {
  constructor(
    public _modelApiKey: "meet_the_team",
    public id: string,
    public eyebrow: string,
    public heading: string,
    public members: EntityMember[]
  ) {}

  static createFromObject(parameters: IBlockMeetTheTeam): EntityBlockMeetTheTeam {
    return new EntityBlockMeetTheTeam(
      parameters._modelApiKey,
      parameters.id,
      parameters.eyebrow,
      parameters.heading,
      parameters.members.map((member) => {
        return EntityMember.createFromObject(member);
      })
    );
  }
}
