import { IFooter } from "@lib/types/IFooter";

import { IButtonInternal } from "@lib/types/IButtonInternal";
import { IButtonExternal } from "@lib/types/IButtonExternal";
import { EntityButtonExternal } from "./EntityButtonExternal";
import { EntityButtonInternal } from "./EntityButtonInternal";

import { BUTTONS } from "../enums/buttons";

import { notEmpty } from "helpers/notEmpty";

const mapButton = (button: IButtonInternal | IButtonExternal) => {
  switch (button._modelApiKey) {
    case BUTTONS.ButtonInternal: {
      return EntityButtonInternal.createFromObject(button);
    }
    case BUTTONS.ButtonExternal: {
      return EntityButtonExternal.createFromObject(button);
    }
    default: {
      return undefined;
    }
  }
};

export class EntityFooter implements IFooter {
  constructor(
    public menu: Array<IButtonInternal | IButtonExternal>,
    public payoff: string,
    public buttonPrivacyPolicy: Array<IButtonInternal | IButtonExternal>
  ) {}

  static createFromObject(parameters: IFooter): EntityFooter {
    return new EntityFooter(
      parameters.menu ? parameters.menu.map(mapButton).filter(notEmpty) : [],
      parameters.payoff,
      parameters.buttonPrivacyPolicy
        ? parameters.buttonPrivacyPolicy.map(mapButton).filter(notEmpty)
        : []
    );
  }
}
