import { TBlocks } from "../types/TBlocks";

import { EntityBlockCompaniesTypeA } from "./EntityBlockCompaniesTypeA";
import { EntityBlockCompaniesTypeB } from "./EntityBlockCompaniesTypeB";
import { EntityBlockHeaderContact } from "./EntityBlockHeaderContact";
import { EntityBlockHeaderTypeA } from "./EntityBlockHeaderTypeA";
import { EntityBlockHeaderTypeB } from "./EntityBlockHeaderTypeB";
import { EntityBlockJobsAll } from "./EntityBlockJobsAll";
import { EntityBlockJobsHighlighted } from "./EntityBlockJobsHighlighted";
import { EntityBlockHowWeWork } from "./EntityBlockHowWeWork";
import { EntityBlockTextImage } from "./EntityBlockTextImage";
import { EntityBlockTextLarge } from "./EntityBlockTextLarge";
import { EntityBlockMeetTheTeam } from "./EntityBlockMeetTheTeam";
import { EntityBlockContactMapAndForm } from "./EntityBlockContactMapAndForm";
import { EntityBlockTestimonial } from "./EntityBlockTestimonial";
import { EntityBlockTestimonialClient } from "./EntityBlockTestimonialClient";
import { EntityBlockOurProcess } from "./EntityBlockOurProcess";

import { BLOCKS } from "../enums/blocks";
import { EntityBlockPurpleBanner } from "./EntityBlockPurpleBanner";

function assertUnreachable(modelApiKey: string): never {
  throw new Error(`Unknown block '${modelApiKey}'`);
}

export const mapBlocksToEntities = (blocks: TBlocks) => {
  return blocks.map((block) => {
    switch (block._modelApiKey) {
      case BLOCKS.BlockCompaniesTypeA: {
        return EntityBlockCompaniesTypeA.createFromObject(block);
      }
      case BLOCKS.BlockCompaniesTypeB: {
        return EntityBlockCompaniesTypeB.createFromObject(block);
      }
      case BLOCKS.BlockHeaderContact: {
        return EntityBlockHeaderContact.createFromObject(block);
      }
      case BLOCKS.BlockHeaderTypeA: {
        return EntityBlockHeaderTypeA.createFromObject(block);
      }
      case BLOCKS.BlockHeaderTypeB: {
        return EntityBlockHeaderTypeB.createFromObject(block);
      }
      case BLOCKS.BlockTextImage: {
        return EntityBlockTextImage.createFromObject(block);
      }
      case BLOCKS.BlockTextLarge: {
        return EntityBlockTextLarge.createFromObject(block);
      }
      case BLOCKS.BlockJobsAll: {
        return EntityBlockJobsAll.createFromObject(block);
      }
      case BLOCKS.BlockJobsHighlighted: {
        return EntityBlockJobsHighlighted.createFromObject(block);
      }
      case BLOCKS.BlockHowWeWork: {
        return EntityBlockHowWeWork.createFromObject(block);
      }
      case BLOCKS.BlockMeetTheTeam: {
        return EntityBlockMeetTheTeam.createFromObject(block);
      }
      case BLOCKS.BlockContactMapAndForm: {
        return EntityBlockContactMapAndForm.createFromObject(block);
      }
      case BLOCKS.BlockTestimonial: {
        return EntityBlockTestimonial.createFromObject(block);
      }
      case BLOCKS.BlockTestimonialClient: {
        return EntityBlockTestimonialClient.createFromObject(block);
      }
      case BLOCKS.BlockOurProcess: {
        return EntityBlockOurProcess.createFromObject(block);
      }
      case BLOCKS.BlockPurpleBanner: {
        return EntityBlockPurpleBanner.createFromObject(block);
      }
      default:
        assertUnreachable(block._modelApiKey);
    }
  });
};
