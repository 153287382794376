import * as React from "react";
import Link from "next/link";

export type TButton = {
  className?: string;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler;
  onMouseEnter?: React.MouseEventHandler;
  onMouseLeave?: React.MouseEventHandler;
  // external page props
  url?: string;
  openInNewWindow?: boolean;
  // internal page props
  page?: {
    slug?: string;
  };
  // disabled
  disabled?: boolean;
  type?: "button" | "reset" | "submit";
};

const Button = (props: TButton) => {
  // external anchor
  if ("url" in props) {
    const newWindow = props.openInNewWindow
      ? {
          target: "_blank",
          rel: "noreferrer"
        }
      : {};
    return (
      <a
        className={props.className}
        href={props.url}
        onClick={props.onClick}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        {...newWindow}
      >
        {props.children}
      </a>
    );
  }

  // internal anchor
  if (props.page && "slug" in props.page) {
    return (
      <Link href={`/${props.page.slug}`} scroll={false}>
        <a
          className={props.className}
          onClick={props.onClick}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        >
          {props.children}
        </a>
      </Link>
    );
  }

  // button
  return (
    <button
      className={props.className}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      disabled={props.disabled}
      type={props.type}
    >
      {props.children}
    </button>
  );
};

export default Button;
