import * as React from "react";
import { IRichText } from "./types";
import StoreModal, { MODAL_TYPES } from "@stores/StoreModal";
import { useRouter } from "next/router";
import * as Analytics from "@lib/tracking/Analytics";

function createMarkup(html: string) {
  return { __html: html };
}

const RichText = (props: IRichText, ref: React.LegacyRef<HTMLDivElement>) => {
  const router = useRouter();
  const storeModal = React.useContext(StoreModal);
  const richText = React.useRef<HTMLDivElement>(null);
  const { className, richtext, trackingCategory = "richtext" } = props;

  React.useEffect(() => {
    if (!richText.current) return;

    function handleClick(e: Event) {
      if (e.currentTarget instanceof Element) {
        const href = e.currentTarget.getAttribute("href");
        const target = e.currentTarget.getAttribute("target");

        if (href === MODAL_TYPES.OPEN_APPLICATION) {
          e.preventDefault();
          storeModal.open(MODAL_TYPES.OPEN_APPLICATION);

          Analytics.trackEvent("click", trackingCategory, "Open Application");
          return;
        } else if (href?.startsWith("/")) {
          if (target !== "_blank") {
            e.preventDefault();
            router.push(href, undefined, { scroll: false });
            return;
          }
        }

        if (href) {
          const title = e.currentTarget.textContent;
          const label = title || decodeURI(href);
          const value = title ? decodeURI(href) : undefined;
          Analytics.trackEvent("click", trackingCategory, label, value);
        }
      }
    }

    const anchors = richText.current.querySelectorAll("a");

    if (!anchors.length) return;

    anchors.forEach((anchor) => {
      anchor.addEventListener("click", handleClick);
    });

    return () => {
      anchors.forEach((anchor) => {
        anchor.removeEventListener("click", handleClick);
      });
    };
  }, [richText, storeModal, router, trackingCategory]);

  return (
    <div ref={richText} className={className} dangerouslySetInnerHTML={createMarkup(richtext)} />
  );
};

export default React.forwardRef(RichText);
