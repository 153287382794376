import styles from "./InputUploadCv.module.scss";

import * as React from "react";
import { Field } from "formik";

import { EntityInputUploadCv } from "@lib/entities/EntityInputUploadCv";
import InputError from "../input-error/InputError";

const MAX_MB = Math.min(25, Number(process.env.NEXT_PUBLIC_UPLOAD_MAX_MB));

interface IInputUploadCv extends EntityInputUploadCv {
  isError: boolean;
  isTouched: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const InputUploadCv = (props: IInputUploadCv) => {
  const [name, setName] = React.useState<string>("");

  const className = props.isError && props.isTouched ? styles.inputError : styles.input;

  const classNameLabel = name ? styles.labelFilled : styles.label;

  return (
    <div className={styles.wrapper}>
      <label
        className={className}
        htmlFor={props.id}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === " " || e.key === "Spacebar") {
            e.preventDefault();
            e.currentTarget.click();
          }
        }}
      >
        {name ? name : null}
      </label>
      <div className={classNameLabel}>{props.placeholder}</div>

      <svg
        className={styles.icon}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6 18L7.41 19.41L15 11.83V30H17V11.83L24.59 19.41L26 18L16 8L6 18Z" />
        <path d="M6 8V4H26V8H28V4C28 3.46957 27.7893 2.96086 27.4142 2.58579C27.0391 2.21071 26.5304 2 26 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V8H6Z" />
      </svg>

      <Field
        type="file"
        id={props.id}
        name={props.id}
        hidden
        value={undefined} // https://stackoverflow.com/questions/66876022/setfieldvalue-formik-and-invalidstateerror-failed-to-set-the-value-property
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            if (fileReader.readyState === 2) {
              props.setFieldValue(props.id, fileReader.result);
            }
          };

          if (e.target.files && e.target.files[0]) {
            if (e.target.files[0].size / 1024 / 1024 > MAX_MB) {
              // FIXME: optimize
              alert(`File can't be larger than ${MAX_MB}MB`);
              props.setFieldValue(props.id, "");
              e.target.value = "";
              setName("");
            } else {
              fileReader.readAsDataURL(e.target.files[0]);
              setName(e.target.files[0].name);
            }
          } else {
            props.setFieldValue(props.id, "");
            setName("");
          }
        }}
      />

      {props.isError && props.isTouched ? <InputError>{props.error}</InputError> : null}
    </div>
  );
};

export default InputUploadCv;
