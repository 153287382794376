import { EntityInputSelect } from "@lib/entities/EntityInputSelect";
import { EntityInputText } from "@lib/entities/EntityInputText";
import { EntityInputUploadCv } from "@lib/entities/EntityInputUploadCv";
import { FormikValues } from "formik";
import { INPUT_FIELDS } from "@lib/enums/inputFields";
import { EntityInputCheckbox } from "@lib/entities/EntityInputCheckbox";
import { FORM_STATUS } from "./Form";
import { EntityJob } from "@lib/entities/EntityJob";
import { trackEvent } from "@lib/tracking/Analytics";

// FIXME solve any
export const handleSubmit =
  (
    subject: string,
    mailTo: string[],
    inputFields: Array<
      EntityInputText | EntityInputCheckbox | EntityInputSelect | EntityInputUploadCv
    >,
    setStatus: (status: FORM_STATUS) => void,
    job?: EntityJob
  ) =>
  (formValues: FormikValues, actions: any) => {
    const mailSubject = job ? `Please reach out to me about "${job.title}"` : subject;

    const data = Object.entries(formValues).map(([id, value]) => {
      const inputField = inputFields.find((inputField) => inputField.id === id);

      return {
        id,
        value,
        name: inputField?.name,
        key: inputField?._modelApiKey
      };
    });

    const sortedData: any = inputFields.map((field) => {
      return data.find((item) => item.id === field.id);
    });

    if (job) {
      sortedData.unshift({
        id: "JobUrl",
        key: "JobUrl",
        value: window.location.href,
        name: "Job Url"
      });
    }

    const body = JSON.stringify({
      mailTo,
      subject: mailSubject,
      data: sortedData
    });

    trackEvent("event", "form", "send", mailSubject, true);

    fetch("/api/form/submit", {
      method: "POST",
      body
    })
      .then(() => {
        trackEvent("event", "form", "success", mailSubject, true);
        setStatus(FORM_STATUS.Send);
      })
      .catch((err) => {
        console.log(err);
        trackEvent("event", "form", "error", mailSubject, true);
        setStatus(FORM_STATUS.Error);
      });

    actions.setSubmitting(false);
  };
