import { IHowWeWorkItem } from "@lib/types/IHowWeWorkItem";
import { IResponsiveImage } from "@lib/types/IResponsiveImage";

export class EntityHowWeWorkItem implements IHowWeWorkItem {
  constructor(
    public _modelApiKey: "how_we_work_item",
    public id: string,
    public image: IResponsiveImage,
    public heading: string,
    public copy: string
  ) {}

  static createFromObject(parameters: IHowWeWorkItem): EntityHowWeWorkItem {
    return new EntityHowWeWorkItem(
      parameters._modelApiKey,
      parameters.id,
      parameters.image,
      parameters.heading,
      parameters.copy
    );
  }
}
