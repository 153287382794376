import styles from "./ButtonHamburger.module.scss";

import * as React from "react";
import IconHamburger from "@components/icons/IconHamburger";
import Button from "@components/buttons/Button";

interface IButtonHamburger {
  isNavigationInverted?: boolean;
  onClick: () => void;
}

const ButtonHamburger = (props: IButtonHamburger) => {
  const classNameIcon = props.isNavigationInverted ? styles.iconInverted : styles.icon;
  return (
    <Button className={styles.button} onClick={props.onClick}>
      <IconHamburger className={classNameIcon} />
    </Button>
  );
};

export default ButtonHamburger;
