import styles from "./Menu.module.scss";

import * as React from "react";

import StoreMenu from "@stores/StoreMenu";
import Button from "@components/buttons/Button";
import { useRouter } from "next/router";
import { EntityNavigation } from "@lib/entities/EntityNavigation";
import { EntityGlobal } from "@lib/entities/EntityGlobal";
import ButtonLogo from "@components/button-logo/ButtonLogo";
import { handleClose } from "./handlers";
import ButtonMenu from "@components/button-menu/ButtonMenu";

import * as Analytics from "@lib/tracking/Analytics";

export type TMenu = {
  className?: string;
  isNavigationInverted?: boolean;
  navigation: EntityNavigation;
  global: EntityGlobal;
};

const Menu = (props: TMenu) => {
  const router = useRouter();
  const storeMenu = React.useContext(StoreMenu);

  React.useEffect(() => {
    router.events.on("routeChangeComplete", () => {
      storeMenu.close();
    });
  }, [router, storeMenu]);

  if (!storeMenu.isOpen) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.header}>
          <ButtonLogo
            isNavigationInverted={true}
            onClick={() => {
              Analytics.trackEvent("click", "mobile navigation", "Logo");
            }}
          />
          <Button
            className={styles.buttonClose}
            onClick={() => {
              Analytics.trackEvent("click", "mobile navigation", "Close menu");
              handleClose(storeMenu)();
            }}
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10 22L16 16L22 22" strokeWidth="2" strokeLinejoin="round" />
              <path d="M22 10L16 16L10 10" strokeWidth="2" strokeLinejoin="round" />
            </svg>
          </Button>
        </div>

        <ul className={styles.list}>
          {props.navigation.menu.map((item) => {
            return (
              <li key={item.id} className={styles.listItem}>
                <ButtonMenu
                  {...item}
                  onClick={() => {
                    Analytics.trackEvent("click", "mobile navigation", item.title);
                  }}
                >
                  {item.title}
                </ButtonMenu>
              </li>
            );
          })}
        </ul>
      </div>

      <div className={styles.contact}>
        <div>{props.global.companyName}</div>
        <div>{props.global.address}</div>
        <div>
          {props.global.zipcode} {props.global.city}
        </div>
        <div>{props.global.phone}</div>
        <div>{props.global.email}</div>
      </div>
    </div>
  );
};

export default Menu;
