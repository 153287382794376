import { IResponsiveImage } from "@lib/types/IResponsiveImage";
import { IMember } from "../types/IMember";

export class EntityMember implements IMember {
  constructor(
    public id: string,
    public firstname: string,
    public lastname: string,
    public jobtitle: string,
    public image: IResponsiveImage,
    public email: string,
    public phone: string,
    public linkedin: string
  ) {}

  static createFromObject(parameters: IMember): EntityMember {
    return new EntityMember(
      parameters.id,
      parameters.firstname,
      parameters.lastname,
      parameters.jobtitle,
      parameters.image,
      parameters.email,
      parameters.phone,
      parameters.linkedin
    );
  }
}
