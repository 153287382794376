import { INPUT_FIELDS } from "../enums/inputFields";
import { IInputText } from "@lib/types/IInputText";
import { IInputCheckbox } from "@lib/types/IInputCheckbox";
import { IInputSelect } from "@lib/types/IInputSelect";
import { IInputUploadCv } from "@lib/types/IInputUploadCv";
import { EntityInputText } from "./EntityInputText";
import { EntityInputCheckbox } from "./EntityInputCheckbox";
import { EntityInputSelect } from "./EntityInputSelect";
import { EntityInputUploadCv } from "./EntityInputUploadCv";

function assertUnreachable(modelApiKey: string): never {
  throw new Error(`Unknown block '${modelApiKey}'`);
}

export const mapInputFieldsToEntities = (
  inputFields: Array<IInputText | IInputCheckbox | IInputSelect | IInputUploadCv>
) => {
  return inputFields.map((inputField) => {
    switch (inputField._modelApiKey) {
      case INPUT_FIELDS.InputText: {
        return EntityInputText.createFromObject(inputField);
      }
      case INPUT_FIELDS.InputCheckbox: {
        return EntityInputCheckbox.createFromObject(inputField);
      }
      case INPUT_FIELDS.InputSelect: {
        return EntityInputSelect.createFromObject(inputField);
      }
      case INPUT_FIELDS.InputUploadCv: {
        return EntityInputUploadCv.createFromObject(inputField);
      }
      default:
        assertUnreachable(inputField._modelApiKey);
    }
  });
};
