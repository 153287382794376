import { BUTTONS } from "@lib/enums/buttons";
import { IResponsiveImage } from "@lib/types/IResponsiveImage";
import { notEmpty } from "helpers/notEmpty";
import { IMap } from "../types/IMap";
import { EntityButtonExternal } from "./EntityButtonExternal";
import { EntityButtonInternal } from "./EntityButtonInternal";

export class EntityMap implements IMap {
  constructor(
    public _modelApiKey: "map",
    public id: string,
    public image: IResponsiveImage,
    public title: string,
    public location: string,
    public buttons: (EntityButtonExternal | EntityButtonInternal)[],
    public positionX: number,
    public positionY: number
  ) {}

  static createFromObject(parameters: IMap): EntityMap {
    const buttons = parameters.buttons
      .map((button) => {
        switch (button._modelApiKey) {
          case BUTTONS.ButtonInternal: {
            return EntityButtonInternal.createFromObject(button);
          }
          case BUTTONS.ButtonExternal: {
            return EntityButtonExternal.createFromObject(button);
          }
          default: {
            return undefined;
          }
        }
      })
      .filter(notEmpty);

    return new EntityMap(
      parameters._modelApiKey,
      parameters.id,
      parameters.image,
      parameters.title,
      parameters.location,
      buttons,
      parameters.positionX,
      parameters.positionY
    );
  }
}
