import "@styles/globals.scss";
import * as React from "react";

import type { AppProps } from "next/app";
import Head from "next/head";
import type { NextPage } from "next";
import { StoreMenuProvider } from "@stores/StoreMenu";
import useWindowSize from "@hooks/useWindowSize";
import useOrientationChange from "@hooks/useOrientationChange";
import { pxToRem } from "helpers/pxToRem";
import { StoreModalProvider } from "@stores/StoreModal";
import { SeoOrFaviconTag, useQuerySubscription } from "react-datocms";
import { EntityNavigation } from "@lib/entities/EntityNavigation";
import { EntityFooter } from "@lib/entities/EntityFooter";
import { EntityGlobal } from "@lib/entities/EntityGlobal";
import Navigation from "@components/navigation/Navigation";
import Menu from "@components/menu/Menu";
import { EntityPage } from "@lib/entities/EntityPage";
import { EntityJob } from "@lib/entities/EntityJob";
import CookieConsent from "@components/cookie-consent/CookieConsent";
import Modal from "@components/modal/Modal";
import PreviewBar from "@components/previewBar/PreviewBar";
import PageTransition from "@components/pageTransition/PageTransition";
import TransitionDataProvider from "@hooks/useTransitionData";
import { EntitySite } from "@lib/entities/EntitySite";
import Script from "next/script";
import { useRouter } from "next/router";

import * as Analytics from "@lib/tracking/Analytics";

export type TPageLayout = {
  page: React.ReactNode;
  preview?: boolean;
  seo?: SeoOrFaviconTag[];
  navigation: EntityNavigation;
  footer: EntityFooter;
  global: EntityGlobal;
  site: EntitySite;
  isNavigationInverted?: boolean;
};

type NextPageWithLayout = NextPage & {
  getLayout?: (props: TPageLayout) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const { width, height, documentFontSize } = useWindowSize();
  const { height: heightOrientation } = useOrientationChange();

  // set some convenient global css values
  React.useEffect(() => {
    if (width == undefined) return;
    if (height == undefined) return;
    if (heightOrientation == undefined) return;
    if (documentFontSize == undefined) return;

    let scrollbarWidth = width - document.body.clientWidth;
    let contentWidth = pxToRem({ value: 1360, documentFontSize });
    let contentOffset = pxToRem({
      value: (width - scrollbarWidth - contentWidth) / 2,
      documentFontSize
    });

    if (width < 1024) {
      contentWidth = pxToRem({ value: 327, documentFontSize });
      contentOffset = pxToRem({
        value: (width - scrollbarWidth - contentWidth) / 2,
        documentFontSize
      });
    }

    document.body.style.setProperty("--vh-resize", `${height * 0.01}px`);
    document.body.style.setProperty("--vh-orientation", `${heightOrientation * 0.01}px`);
    document.body.style.setProperty("--scrollbar-width", `${scrollbarWidth}px`);
    document.body.style.setProperty("--content-width", `${contentWidth}px`);
    document.body.style.setProperty("--content-offset", `${contentOffset}px`);
  }, [width, height, heightOrientation, documentFontSize]);

  // keep styles
  // https://github.com/vercel/next.js/issues/17464
  React.useEffect(() => {
    Array.from(document.querySelectorAll('head > link[rel="stylesheet"][data-n-p]')).forEach(
      (node) => {
        node.removeAttribute("data-n-p");
      }
    );
    const mutationHandler: MutationCallback = (mutations) => {
      mutations.forEach(({ target }) => {
        if (target.nodeName === "STYLE") {
          const script = target as HTMLStyleElement;
          if (script.getAttribute("media") === "x") {
            script.removeAttribute("media");
          }
        }
      });
    };
    const observer = new MutationObserver(mutationHandler);
    observer.observe(document.head, {
      subtree: true,
      attributeFilter: ["media"]
    });
    return () => {
      observer.disconnect();
    };
  }, []);

  const getLayout = Component.getLayout ?? ((page) => page);

  // FIXME: add error handling
  const { data: dataSite } = useQuerySubscription(pageProps.subscriptionSite || {});
  const { data: dataGlobal } = useQuerySubscription(pageProps.subscriptionGlobal || {});
  const { data: dataNavigation } = useQuerySubscription(pageProps.subscriptionNavigation || {});
  const { data: dataFooter } = useQuerySubscription(pageProps.subscriptionFooter || {});
  const { data: dataPage } = useQuerySubscription(pageProps.subscriptionPage || {});
  const { data: dataPageJob } = useQuerySubscription(pageProps.subscriptionPageJob || {});

  const navigation = EntityNavigation.createFromObject(dataNavigation?.data || {});
  const footer = EntityFooter.createFromObject(dataFooter?.data || {});
  const site = EntitySite.createFromObject(dataSite?.data || {});
  const global = EntityGlobal.createFromObject(dataGlobal?.data || {});
  const page = dataPage?.data ? EntityPage.createFromObject(dataPage?.data || {}) : null;
  const job = dataPageJob?.data ? EntityJob.createFromObject(dataPageJob?.data || {}) : null;

  const seo = page?.seo || job?.seo;
  const isNavigationInverted = page?.isNavigationInverted;
  const slug = page?.slug || `job/${job?.slug}` || "";
  const preview = pageProps.preview;

  React.useEffect(() => {
    Analytics.setIsInPreviewMode(!!preview);
  }, [preview]);

  const layoutData: TPageLayout = {
    // @ts-ignore-next-line
    page: <Component {...pageProps} />,
    preview,
    isNavigationInverted,
    seo,
    navigation,
    footer,
    global,
    site
  };

  return (
    <TransitionDataProvider>
      <StoreMenuProvider>
        <StoreModalProvider>
          <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          </Head>

          {/* Give main body a title element */}
          <h1 style={{ display: "none" }}>Doghouse</h1>
          <Navigation navigation={navigation} isNavigationInverted={isNavigationInverted} />
          <Menu
            navigation={navigation}
            global={global}
            isNavigationInverted={isNavigationInverted}
          />

          <PageTransition slug={slug} global={global}>
            {getLayout(layoutData)}
          </PageTransition>

          <Analytics.TrackPage />

          <CookieConsent global={global} />
          <Modal global={global} />
          {preview && <PreviewBar />}
        </StoreModalProvider>
      </StoreMenuProvider>
    </TransitionDataProvider>
  );
}

export default MyApp;
