import styles from "./InputSelect.module.scss";

import * as React from "react";
import { Field } from "formik";

import { EntityInputSelect } from "@lib/entities/EntityInputSelect";
import InputError from "../input-error/InputError";

interface IInputSelect extends EntityInputSelect {
  isError: boolean;
  isTouched: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const InputSelect = (props: IInputSelect) => {
  const [value, setValue] = React.useState<string>("");

  function handleChange(e: any) {
    props.setFieldValue(props.id, e.target.value);
    setValue(e.target.value);
  }

  function handleFirstTime() {
    // set first value after initial click
    if (!value) {
      const option = props.getOptions()[0];
      props.setFieldValue(props.id, option);
      setValue(option);
    }
  }

  const className =
    props.isError && props.isTouched
      ? value
        ? styles.selectError
        : styles.selectErrorHidden
      : value
      ? styles.select
      : styles.selectHidden;

  const classNameLabel = value ? styles.labelFilled : styles.label;

  return (
    <div className={styles.wrapper}>
      <svg
        className={styles.icon}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10 13L16 19L22 13" strokeWidth="2" strokeLinejoin="round" />
      </svg>

      <Field
        component="select"
        className={className}
        name={props.id}
        onClick={handleFirstTime}
        onKeyDown={(e: any) => {
          if (e.key === " " || e.key === "Spacebar") {
            handleFirstTime();
          }
        }}
        onChange={handleChange}
      >
        {props.getOptions().map((option) => {
          return (
            <option className={styles.option} key={option} value={option}>
              {option}
            </option>
          );
        })}
      </Field>
      <label className={classNameLabel}>{props.placeholder}</label>
      {props.isError && props.isTouched ? <InputError>{props.error}</InputError> : null}
    </div>
  );
};

export default InputSelect;
