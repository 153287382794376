// Map Blocks to DatoCMS _modelApiKey
export enum BLOCKS {
  "BlockCompaniesTypeA" = "companies_type_a",
  "BlockCompaniesTypeB" = "companies_type_b",
  "BlockHeaderContact" = "header_contact",
  "BlockHeaderTypeA" = "header_type_a",
  "BlockHeaderTypeB" = "header_type_b",
  "BlockTextImage" = "text_image",
  "BlockTextLarge" = "text_large",
  "BlockJobsAll" = "jobs_all",
  "BlockJobsHighlighted" = "jobs_highlighted",
  "BlockHowWeWork" = "how_we_work",
  "BlockMeetTheTeam" = "meet_the_team",
  "BlockContactMapAndForm" = "contact_map_and_form",
  "BlockTestimonial" = "testimonial",
  "BlockTestimonialClient" = "testimonial_client",
  "BlockOurProcess" = "our_process",
  "BlockPurpleBanner" = "purple_banner"
}
