import { IInternalLink } from "../types/IInternalLink";

export class EntityInternalLink implements IInternalLink {
  constructor(
    public _modelApiKey: "internal_link",
    public id: string,
    public page: { slug: string }
  ) {}

  static createFromObject(parameters: IInternalLink): EntityInternalLink {
    return new EntityInternalLink(parameters._modelApiKey, parameters.id, parameters.page);
  }
}
