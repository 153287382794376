import { EntityButtonExternal } from "./EntityButtonExternal";
import { EntityButtonInternal } from "./EntityButtonInternal";
import { IBlockJobsHighlighted } from "../types/IBlockJobsHighlighted";
import { EntityJob } from "./EntityJob";

import { BUTTONS } from "../enums/buttons";

import { notEmpty } from "helpers/notEmpty";

export class EntityBlockJobsHighlighted implements IBlockJobsHighlighted {
  constructor(
    public _modelApiKey: "jobs_highlighted",
    public id: string,
    public eyebrow: string,
    public heading: string,
    public jobs: EntityJob[],
    public buttons: Array<EntityButtonInternal | EntityButtonExternal>
  ) {}

  static createFromObject(parameters: IBlockJobsHighlighted): EntityBlockJobsHighlighted {
    const buttons = parameters.buttons
      .map((button) => {
        switch (button._modelApiKey) {
          case BUTTONS.ButtonInternal: {
            return EntityButtonInternal.createFromObject(button);
          }
          case BUTTONS.ButtonExternal: {
            return EntityButtonExternal.createFromObject(button);
          }
          default: {
            return undefined;
          }
        }
      })
      .filter(notEmpty);

    return new EntityBlockJobsHighlighted(
      parameters._modelApiKey,
      parameters.id,
      parameters.eyebrow,
      parameters.heading,
      parameters.jobs.map((job) => {
        return EntityJob.createFromObject(job);
      }),
      buttons
    );
  }
}
