import styles from "./ModalForm.module.scss";

import * as React from "react";
import StoreModal, { MODAL_TYPES } from "@stores/StoreModal";
import MyForm from "@components/form/Form";
import { EntityForm } from "@lib/entities/EntityForm";
import ButtonCloseModal from "@components/button-close-modal/ButtonCloseModal";
import { EntityGlobal } from "@lib/entities/EntityGlobal";
import * as Analytics from "@lib/tracking/Analytics";

interface IModalFormProps {
  global: EntityGlobal;
  form: EntityForm;
}

const ModalForm = (props: IModalFormProps) => {
  const storeModal = React.useContext(StoreModal);

  return (
    <div className={styles.container}>
      <MyForm form={props.form} global={props.global} modal />
      <ButtonCloseModal
        onClick={() => {
          storeModal.close();
          Analytics.trackEvent("click", "modal", "Close Modal");
        }}
      />
    </div>
  );
};

export default ModalForm;
