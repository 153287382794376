import { IExternalLink } from "../types/IExternalLink";

export class EntityExternalLink implements IExternalLink {
  constructor(
    public _modelApiKey: "external_link",
    public id: string,
    public url: string,
    public openInNewWindow: boolean
  ) {}

  static createFromObject(parameters: IExternalLink): EntityExternalLink {
    return new EntityExternalLink(
      parameters._modelApiKey,
      parameters.id,
      parameters.url,
      parameters.openInNewWindow
    );
  }
}
