import styles from "./ButtonLogo.module.scss";

import * as React from "react";
import Logo from "@components/logos/Logo";
import Button from "@components/buttons/Button";

interface IButtonLogo {
  isNavigationInverted?: boolean;
  onClick: () => void;
}

const ButtonLogo = (props: IButtonLogo) => {
  const { isNavigationInverted, onClick } = props;
  const classNameLogo = isNavigationInverted ? styles.logoInverted : styles.logo;

  return (
    <Button className={styles.buttonLogo} page={{ slug: "" }} onClick={onClick}>
      <Logo className={classNameLogo} />
    </Button>
  );
};

export default ButtonLogo;
