import { IForm } from "../types/IForm";
import { EntityInputCheckbox } from "./EntityInputCheckbox";

import { EntityInputSelect } from "./EntityInputSelect";
import { EntityInputText } from "./EntityInputText";
import { EntityInputUploadCv } from "./EntityInputUploadCv";

import { mapTermsToEntities } from "./mapTermsToEntities";
import { mapInputFieldsToEntities } from "./mapInputFieldsToEntities";

export class EntityForm implements IForm {
  constructor(
    public id: string,
    public heading: string,
    public labelButtonSubmit: string,
    public terms: Array<EntityInputCheckbox>,
    public inputFields: Array<
      EntityInputText | EntityInputCheckbox | EntityInputSelect | EntityInputUploadCv
    >,
    public mailSubject: string,
    public mailTo: string,
    public messageSuccessHeading: string,
    public messageSuccessParagraph: string
  ) {}

  static createFromObject(parameters: IForm): EntityForm {
    return new EntityForm(
      parameters.id,
      parameters.heading,
      parameters.labelButtonSubmit,
      mapTermsToEntities(parameters.terms),
      mapInputFieldsToEntities(parameters.inputFields),
      parameters.mailSubject,
      parameters.mailTo,
      parameters.messageSuccessHeading,
      parameters.messageSuccessParagraph
    );
  }

  getAllFields = (): Array<
    EntityInputText | EntityInputCheckbox | EntityInputSelect | EntityInputUploadCv
  > => {
    return [...this.inputFields, ...this.terms];
  };
}
