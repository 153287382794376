import styles from "./ButtonNavigation.module.scss";

import * as React from "react";
import Button, { TButton } from "@components/buttons/Button";
import { useRouter } from "next/router";

interface IProps {
  children: React.ReactNode;
  isNavigationInverted?: boolean;
  button: TButton;
  onClick: () => void;
}

const ButtonNavigation = (props: IProps) => {
  const router = useRouter();
  const { query } = router;
  const slug = query.slug ? query.slug[0] : undefined;

  const { children, onClick, ...rest } = props;

  const stylesButtonActive = props.isNavigationInverted
    ? styles.buttonActiveInverted
    : styles.buttonActive;
  const stylesButton = props.isNavigationInverted ? styles.buttonInverted : styles.button;

  const className =
    slug && props.button.page?.slug
      ? props.button.page.slug.indexOf(slug) > -1
        ? stylesButtonActive
        : stylesButton
      : stylesButton;

  return (
    <Button className={className} onClick={onClick} {...props.button}>
      {children}
    </Button>
  );
};

export default ButtonNavigation;
