import { IBlockCompaniesTypeB } from "../types/IBlockCompaniesTypeB";
import { ICompany } from "../types/ICompany";
import { EntityCompany } from "./EntityCompany";

export class EntityBlockCompaniesTypeB implements IBlockCompaniesTypeB {
  constructor(
    public _modelApiKey: "companies_type_b",
    public id: string,
    public eyebrow: string,
    public heading: string,
    public companies: Array<ICompany>
  ) {}

  static createFromObject(parameters: IBlockCompaniesTypeB): EntityBlockCompaniesTypeB {
    return new EntityBlockCompaniesTypeB(
      parameters._modelApiKey,
      parameters.id,
      parameters.eyebrow,
      parameters.heading,
      parameters.companies.map((company) => {
        return EntityCompany.createFromObject(company);
      })
    );
  }
}
