import { IBlockHeaderContact } from "@lib/types/IBlockHeaderContact";

export class EntityBlockHeaderContact implements IBlockHeaderContact {
  constructor(
    public _modelApiKey: "header_contact",
    public id: string,
    public heading: string,
    public phone: string,
    public mail: string,
    public address: string
  ) {}

  static createFromObject(parameters: IBlockHeaderContact): EntityBlockHeaderContact {
    return new EntityBlockHeaderContact(
      parameters._modelApiKey,
      parameters.id,
      parameters.heading,
      parameters.phone,
      parameters.mail,
      parameters.address
    );
  }
}
