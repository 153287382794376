import { ISite } from "../types/ISite";

export class EntitySite implements ISite {
  constructor(
    public globalSeo: {
      siteName: string;
      fallbackSeo: {
        image: {
          width: number;
          height: number;
          url: string;
        };
      };
    }
  ) {}

  static createFromObject(parameters: ISite): EntitySite {
    return new EntitySite(parameters.globalSeo);
  }
}
