import styles from "./PreviewBar.module.scss";

import * as React from "react";
import Container from "@components/containers/Container";
import Link from "next/link";
import { useRouter } from "next/router";

const PreviewBar = () => {
  const router = useRouter();
  const path = router.asPath.replace(/^\//, "");

  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.content}>
          <div>Preview Enabled</div>
          <Link href={`/api/disable-preview?slug=${path}`} prefetch={false}>
            <a>Disable Preview Mode</a>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default PreviewBar;
