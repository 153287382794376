import { ICompany } from "@lib/types/ICompany";
import { IResponsiveImage } from "@lib/types/IResponsiveImage";

export class EntityCompany implements ICompany {
  constructor(
    public id: string,
    public name: string,
    public logoWhite: IResponsiveImage,
    public logoBlack: IResponsiveImage
  ) {}

  static createFromObject(parameters: ICompany): EntityCompany {
    return new EntityCompany(
      parameters.id,
      parameters.name,
      parameters.logoWhite,
      parameters.logoBlack
    );
  }
}
