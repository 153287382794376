import styles from "./InputCheckbox.module.scss";

import * as React from "react";
import { Field } from "formik";

import { EntityInputCheckbox } from "@lib/entities/EntityInputCheckbox";
import InputError from "../input-error/InputError";
import RichText from "@components/rich-text/RichText";

interface IInputCheckbox extends EntityInputCheckbox {
  isError: boolean;
  isTouched: boolean;
}

const InputCheckbox = (props: IInputCheckbox) => {
  const className = props.isError && props.isTouched ? styles.labelError : styles.label;

  return (
    <div className={styles.wrapper}>
      <div>
        <Field type="checkbox" className={styles.checkbox} id={props.id} name={props.id}></Field>
        <label htmlFor={props.id} className={className}>
          <span>
            <RichText richtext={props.label} />
          </span>
        </label>
      </div>
      {props.isError && props.isTouched ? <InputError>{props.error}</InputError> : null}
    </div>
  );
};

export default InputCheckbox;
