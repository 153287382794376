import * as React from "react";

interface IIconHamburgerProps {
  className?: string;
}

const IconHamburger = (props: IIconHamburgerProps) => {
  return (
    <svg
      className={props.className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 10H26" strokeWidth="2" />
      <path d="M6 16H26" strokeWidth="2" />
      <path d="M6 22H26" strokeWidth="2" />
    </svg>
  );
};

export default IconHamburger;
