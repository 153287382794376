import { useTransitionData } from "@hooks/useTransitionData";
import { EntityGlobal } from "@lib/entities/EntityGlobal";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { useRouter } from "next/router";
import * as React from "react";

interface IProps {
  slug: string;
  global: EntityGlobal;
  children: React.ReactNode;
}

function getType(slug: string, jobListingSlug: string) {
  if (slug.startsWith(jobListingSlug)) {
    return "job-listing";
  } else if (slug.startsWith("job/")) {
    return "job-detail";
  } else {
    return "page";
  }
}

function getVariants(pageType?: string, previousPageType?: string): Variants {
  const x = previousPageType === "job-listing" && pageType === "job-detail";
  const y = previousPageType === "job-detail" && pageType === "job-listing";

  return {
    hidden: () => {
      return x ? { y: "100%" } : y ? { position: "absolute", top: 0, zIndex: -1, y: 0 } : { y: 0 };
    },
    enter: () => {
      return x
        ? { y: 0, transition: { duration: 1 } }
        : y
        ? { y: 0, transition: { duration: 1 } }
        : { y: 0, transition: { duration: 0 } };
    },
    exit: () => {
      return x
        ? { position: "absolute", zIndex: -1, y: 0, transition: { duration: 1 } }
        : y
        ? { y: "100%", transition: { duration: 1 } }
        : { y: 0, position: "absolute", transition: { duration: 0 } };
    }
  };
}

const PageTransition = (props: IProps) => {
  const transitionData = useTransitionData();
  const router = useRouter();

  React.useEffect(() => {
    const slugWithoutQueryString = transitionData.slug?.replace(/\?.*/g, "");
    const previousSlugWithoutQueryString = transitionData.previousSlug?.replace(/\?.*/g, "");
    if (slugWithoutQueryString !== previousSlugWithoutQueryString) {
      document.documentElement.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }, [transitionData]);

  const pageType = transitionData.slug
    ? getType(transitionData.slug, props.global.jobsListingPage?.slug || "")
    : undefined;
  const previousPageType = transitionData.previousSlug
    ? getType(transitionData.previousSlug, props.global.jobsListingPage?.slug || "")
    : undefined;
  const variants = getVariants(pageType, previousPageType);

  return (
    <AnimatePresence
      key="AnimatePresence"
      initial={false}
      onExitComplete={() => {
        // FIXME: animate to top
        // window.scrollTo(0, 0);
      }}
    >
      <motion.div
        key={props.slug}
        style={{ width: "100%" }}
        variants={variants} // Pass the variant object into Framer Motion
        initial="hidden" // Set the initial state to variants.hidden
        animate="enter" // Animated state to variants.enter
        exit="exit" // Exit state (used later) to variants.exit
        transition={{ type: "linear" }} // Set the transition to linear
        className=""
      >
        {props.children}
      </motion.div>
    </AnimatePresence>
  );
};

export default PageTransition;
