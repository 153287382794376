import { IButtonExternal } from "../types/IButtonExternal";

export class EntityButtonExternal implements IButtonExternal {
  constructor(
    public _modelApiKey: "button_external",
    public id: string,
    public title: string,
    public openInNewWindow: boolean,
    public url: string
  ) {}

  static createFromObject(parameters: IButtonExternal): EntityButtonExternal {
    return new EntityButtonExternal(
      parameters._modelApiKey,
      parameters.id,
      parameters.title,
      parameters.openInNewWindow,
      parameters.url
    );
  }
}
