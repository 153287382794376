import Router, { useRouter } from "next/router";
import * as React from "react";

function stripNewlines(value: string) {
  if (!value) {
    return value;
  }
  return value.replace(/(\r\n|\n|\r)/gm, "");
}

class TrackingState {
  public isPreview = false;
  public isEnabled = false;
  public eventsBeforeContent: (PageEvent | CustomEvent)[] = [];
}
const trackingState = new TrackingState();

class PageEvent {
  constructor(public path: string, public title?: string) {}
}

class CustomEvent {
  constructor(
    public action: string = "event",
    public category: string,
    public label: string,
    public value?: any,
    public isNonInteractive?: boolean
  ) {}
}

function removeUndefinedFromCustomParams(obj: Gtag.CustomParams) {
  return Object.keys(obj).reduce<Gtag.CustomParams>((acc, key) => {
    const _acc = acc;
    if (obj[key] !== undefined) _acc[key] = obj[key];
    return _acc;
  }, {});
}

export const TrackPage = () => {
  const router = useRouter();

  React.useEffect(() => {
    if (Router.router?.asPath != null) {
      trackPage(Router.router.asPath, document.title);
    }
  }, []);

  React.useEffect(() => {
    const handleRouteChange = (url: string) => {
      trackPage(url, document.title);
    };
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);

  return null;
};

export function trackPage(path: string, title?: string) {
  const event = new PageEvent(path, title);
  if (!trackingState.isEnabled) {
    trackingState.eventsBeforeContent.push(event);
  } else {
    if (trackingState.isPreview) return;

    if (typeof window !== "undefined" && window.gtag) {
      // console.log(event);
      const customParams = removeUndefinedFromCustomParams({
        page_title: event.title,
        page_location: event.path
      });
      window.gtag("event", "page_view", customParams);
    }
  }
}

export function trackEvent(
  action: string = "event",
  category: string,
  label: string,
  value?: any,
  nonInteraction?: boolean
) {
  const event = new CustomEvent(
    action,
    category,
    stripNewlines(label.substring(0, 99)),
    value ? stripNewlines(value.substring(0, 99)) : value,
    nonInteraction
  );

  if (!trackingState.isEnabled) {
    trackingState.eventsBeforeContent.push(event);
  } else {
    if (trackingState.isPreview) return;

    if (typeof window !== "undefined" && window.gtag) {
      // console.log(event);
      const customParams = removeUndefinedFromCustomParams({
        event_category: event.category,
        event_label: event.label,
        value: event.value,
        non_interaction: event.isNonInteractive,
        page_location: Router.router?.asPath
      });
      window.gtag("event", action, customParams);
    }
  }
}

function sendEventsBeforeConsent() {
  trackingState.eventsBeforeContent.forEach((event) => {
    if (event instanceof PageEvent) {
      trackPage(event.path, event.title);
    } else if (event instanceof CustomEvent) {
      trackEvent(event.action, event.category, event.label, event.value);
    }
  });
}

export function enable() {
  trackingState.isEnabled = true;
  sendEventsBeforeConsent();
}

export function setIsInPreviewMode(bool: boolean) {
  trackingState.isPreview = bool;
}
