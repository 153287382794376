import * as React from "react";
import useInView from "@hooks/useInView";
import * as Analytics from "@lib/tracking/Analytics";

export type TSection = {
  trackingCategory?: string;
  children: React.ReactNode;
  labelledby: string;
  className?: string;
};

const Section = (props: TSection) => {
  const [isInView, setIsInView] = React.useState<boolean>(false);
  const { children, labelledby, trackingCategory, ...rest } = props;
  const { ref, inView } = useInView();

  React.useEffect(() => {
    if (inView && !isInView) {
      setIsInView(true);
      if (trackingCategory) {
        Analytics.trackEvent("scroll", "in view", trackingCategory);
      }
    }
  }, [inView, isInView, trackingCategory]);

  return (
    <section ref={ref} aria-labelledby={labelledby} {...rest}>
      {children}
    </section>
  );
};

export default Section;
