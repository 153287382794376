import styles from "./Container.module.scss";

import * as React from "react";

import { cx } from "helpers/classNames";

import Section from "@components/section/Section";

export type TContainer = {
  trackingCategory?: string;
  as?: string;
  className?: string;
  children: React.ReactNode;
  overflow?: boolean;
  labelledby?: string;
};

const Container = (props: TContainer) => {
  const Tag = (props.as || "div") as keyof JSX.IntrinsicElements;

  const className = props.overflow ? styles.containerOverflow : styles.container;

  if (props.as === "section") {
    return (
      <Section
        className={cx(className, props.className)}
        labelledby={props.labelledby || ""}
        trackingCategory={props.trackingCategory}
      >
        <div className={styles.content}>{props.children}</div>
      </Section>
    );
  }

  return (
    <Tag className={cx(className, props.className)}>
      <div className={styles.content}>{props.children}</div>
    </Tag>
  );
};

export default Container;
