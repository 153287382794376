import Cookies from "js-cookie";

import { COOKIE_CONSENT } from "@lib/enums/cookieConsent";
import { COOKIE_NAMES } from "@lib/enums/cookieNames";

export const handleConsent = (setState: Function) => () => {
  Cookies.set(COOKIE_NAMES.CookieConsent, COOKIE_CONSENT.Consent);
  setState(COOKIE_CONSENT.Consent);
};

export const handleDenial = (setState: Function) => () => {
  Cookies.set(COOKIE_NAMES.CookieConsent, COOKIE_CONSENT.Denied);
  setState(COOKIE_CONSENT.Denied);
};
