var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1b03c01d5edb34163314c1ca2dfabd664fd0d37e"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const environment = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || "PRODUCTION";

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://d940e0871811423ea0dc0ae526413fc3@o4504003353968640.ingest.sentry.io/4504965930745856",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: environment === "PRODUCTION" ? 0.2 : 1.0,
  environment
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
