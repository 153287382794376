import { IButtonHeaderTypeAInternal } from "../types/IButtonHeaderTypeAInternal";

export class EntityButtonHeaderTypeAInternal implements IButtonHeaderTypeAInternal {
  constructor(
    public _modelApiKey: "button_header_type_a_internal",
    public id: string,
    public eyebrow: string,
    public title: string,
    public page: {
      slug: string;
    }
  ) {}

  static createFromObject(parameters: IButtonHeaderTypeAInternal): EntityButtonHeaderTypeAInternal {
    return new EntityButtonHeaderTypeAInternal(
      parameters._modelApiKey,
      parameters.id,
      parameters.eyebrow,
      parameters.title,
      parameters.page
    );
  }
}
