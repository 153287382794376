import styles from "./Navigation.module.scss";

import * as React from "react";

import { INavigation } from "@lib/types/INavigation";
import StoreMenu from "@stores/StoreMenu";

import Container from "@components/containers/Container";
import ButtonNavigation from "@components/buttons/ButtonNavigation";
import ButtonHamburger from "@components/button-hamburger/ButtonHamburger";
import ButtonLogo from "@components/button-logo/ButtonLogo";

import { handleToggle } from "./handlers";
import * as Analytics from "@lib/tracking/Analytics";

export type TNavigation = {
  className?: string;
  isNavigationInverted?: boolean;
  navigation: INavigation;
};

const Navigation = (props: TNavigation) => {
  const storeMenu = React.useContext(StoreMenu);

  const { isNavigationInverted } = props;

  const classNameContainer = isNavigationInverted ? styles.containerInverted : styles.container;

  return (
    <Container className={classNameContainer} as="header">
      <ButtonLogo
        isNavigationInverted={isNavigationInverted}
        onClick={() => {
          Analytics.trackEvent("click", "navigation", "Logo");
        }}
      />
      <ul className={styles.list}>
        {props.navigation.menu.map((item) => {
          return (
            <li key={item.id} className={styles.listItem}>
              <ButtonNavigation
                button={item}
                isNavigationInverted={isNavigationInverted}
                onClick={() => {
                  Analytics.trackEvent("click", "navigation", item.title);
                }}
              >
                {item.title}
              </ButtonNavigation>
            </li>
          );
        })}
      </ul>
      <ButtonHamburger
        isNavigationInverted={isNavigationInverted}
        onClick={() => {
          Analytics.trackEvent("click", "mobile navigation", "Open menu");
          handleToggle(storeMenu)();
        }}
      />
    </Container>
  );
};

export default Navigation;
