import styles from "./CookieConsent.module.scss";

import Cookies from "js-cookie";
import * as React from "react";

import { COOKIE_CONSENT } from "@lib/enums/cookieConsent";
import { COOKIE_NAMES } from "@lib/enums/cookieNames";
import { ICookieConsent } from "./types";

import { getEnumKeyByEnumValue } from "helpers/getEnumKeyByEnumValue";

import ButtonPrimary, { BUTTON_PRIMARY_VARIANTS } from "@components/buttons/ButtonPrimary";
import Container from "@components/containers/Container";
import RichText from "@components/rich-text/RichText";
import { handleDenial, handleConsent } from "./handlers";
import Script from "next/script";

import * as Analytics from "@lib/tracking/Analytics";

const getCookieConsentValue = (): COOKIE_CONSENT => {
  const cookieConsentString: string = Cookies.get(COOKIE_NAMES.CookieConsent) || "";
  const cookieConsentEnum = getEnumKeyByEnumValue(COOKIE_CONSENT, cookieConsentString);
  return cookieConsentEnum ? COOKIE_CONSENT[cookieConsentEnum] : COOKIE_CONSENT.Visible;
};

const CookieConsent = (props: ICookieConsent) => {
  const [state, setState] = React.useState<COOKIE_CONSENT>(COOKIE_CONSENT.Init);

  React.useEffect(() => {
    const cookieConsent = getCookieConsentValue();
    setState(cookieConsent);
  }, []);

  if (state === COOKIE_CONSENT.Visible) {
    return (
      <div className={styles.cookieConsent}>
        <div className={styles.inner}>
          <RichText className={styles.copy} richtext={props.global.cookieConsentParagraph} />
          <div className={styles.buttons}>
            <ButtonPrimary
              variant={BUTTON_PRIMARY_VARIANTS.Filled}
              onClick={handleConsent(setState)}
            >
              {props.global.cookieConsentButtonConsent}
            </ButtonPrimary>
            <ButtonPrimary onClick={handleDenial(setState)}>
              {props.global.cookieConsentButtonDenial}
            </ButtonPrimary>
          </div>
        </div>
      </div>
    );
  }

  if (state === COOKIE_CONSENT.Consent) {
    // Include third party scripts
    return (
      <>
        <Script
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_TRACKING_ID}`}
          onLoad={() => {
            Analytics.enable();
          }}
        />
        <Script
          id="googleAnalytics"
          dangerouslySetInnerHTML={{
            __html: `
                window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.NEXT_PUBLIC_GA_TRACKING_ID}', { 'send_page_view': false, 'anonymize_ip': true });
              `
          }}
        />
      </>
    );
  }

  return null;
};

export default CookieConsent;
