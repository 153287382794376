// FIXME: add typescript stuff

import React, { useState, useEffect } from "react";
import { useInView as reactUseInView } from "react-intersection-observer";

const useInView = () => {
  const { ref, inView, entry } = reactUseInView({
    /* Optional options */
    threshold: 0.1 // at least 10% must be visible
  });

  return { ref, inView, entry };
};

export default useInView;
