import { ASSETS } from "@lib/enums/assets";
import { BUTTONS } from "@lib/enums/buttons";
import { IResponsiveImage } from "@lib/types/IResponsiveImage";
import { notEmpty } from "helpers/notEmpty";
import { IBlockTextLarge } from "../types/IBlockTextLarge";
import { EntityButtonExternal } from "./EntityButtonExternal";
import { EntityButtonInternal } from "./EntityButtonInternal";
import { EntityImage } from "./EntityImage";
import { EntityMap } from "./EntityMap";

export class EntityBlockTextLarge implements IBlockTextLarge {
  constructor(
    public _modelApiKey: "text_large",
    public id: string,
    public paragraph: string,
    public buttons: (EntityButtonInternal | EntityButtonExternal)[],
    public assets: (EntityImage | EntityMap)[]
  ) {}

  static createFromObject(parameters: IBlockTextLarge): EntityBlockTextLarge {
    const assets = parameters.assets
      .map((item) => {
        switch (item._modelApiKey) {
          case ASSETS.Image: {
            return EntityImage.createFromObject(item);
          }
          case ASSETS.Map: {
            return EntityMap.createFromObject(item);
          }
          default: {
            return undefined;
          }
        }
      })
      .filter(notEmpty);

    const buttons = parameters.buttons
      .map((button) => {
        switch (button._modelApiKey) {
          case BUTTONS.ButtonInternal: {
            return EntityButtonInternal.createFromObject(button);
          }
          case BUTTONS.ButtonExternal: {
            return EntityButtonExternal.createFromObject(button);
          }
          default: {
            return undefined;
          }
        }
      })
      .filter(notEmpty);

    return new EntityBlockTextLarge(
      parameters._modelApiKey,
      parameters.id,
      parameters.paragraph,
      buttons,
      assets
    );
  }
}
