import { IInputUploadCv } from "../types/IInputUploadCv";

export class EntityInputUploadCv implements IInputUploadCv {
  constructor(
    public _modelApiKey: "input_upload_cv",
    public id: string,
    public name: string,
    public placeholder: string,
    public error: string,
    public isRequired: boolean
  ) {}

  static createFromObject(parameters: IInputUploadCv): EntityInputUploadCv {
    return new EntityInputUploadCv(
      parameters._modelApiKey,
      parameters.id,
      parameters.name,
      parameters.placeholder,
      parameters.error,
      parameters.isRequired
    );
  }

  validate = (value: string) => {
    if (!value && !this.isRequired) {
      return {
        key: this.id,
        value: null
      };
    }

    // required
    if (!value) {
      return {
        key: this.id,
        value: "Required value"
      };
    }

    return {
      key: this.id,
      value: null
    };
  };
}
