import { IBlockPurpleBanner } from "../types/IBlockPurpleBanner";
import { EntityExternalLink } from "./EntityExternalLink";
import { EntityInternalLink } from "./EntityInternalLink";

import { notEmpty } from "helpers/notEmpty";

export class EntityBlockPurpleBanner implements IBlockPurpleBanner {
  constructor(
    public _modelApiKey: "purple_banner",
    public id: string,
    public heading: string,
    public copy: string,
    public useDarkBackground: boolean,
    public link: Array<EntityInternalLink | EntityExternalLink>
  ) {}

  static createFromObject(parameters: IBlockPurpleBanner): EntityBlockPurpleBanner {
    const link = parameters.link
      .map((item) => {
        switch (item._modelApiKey) {
          case "external_link": {
            return EntityExternalLink.createFromObject(item);
          }
          case "internal_link": {
            return EntityInternalLink.createFromObject(item);
          }
          default: {
            return undefined;
          }
        }
      })
      .filter(notEmpty);

    return new EntityBlockPurpleBanner(
      parameters._modelApiKey,
      parameters.id,
      parameters.heading,
      parameters.copy,
      parameters.useDarkBackground,
      link
    );
  }
}
