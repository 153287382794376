import * as React from "react";

export interface IStoreMenu {
  isOpen: boolean;
  toggle: () => void;
  close: () => void;
}

const defaultValues = {
  isOpen: false,
  toggle: () => {},
  close: () => {}
};

const StoreMenu = React.createContext<IStoreMenu>(defaultValues);

interface IStoreMenuProvider {
  children?: React.ReactNode;
}

export const StoreMenuProvider = (props: IStoreMenuProvider) => {
  const [isOpen, setIsOpen] = React.useState(defaultValues.isOpen);

  return (
    <StoreMenu.Provider
      value={{
        isOpen,
        toggle: () => {
          setIsOpen(!isOpen);
        },
        close: () => {
          setIsOpen(false);
        }
      }}
    >
      {props.children}
    </StoreMenu.Provider>
  );
};

export default StoreMenu;
