import { IButtonInternal } from "../types/IButtonInternal";

export class EntityButtonInternal implements IButtonInternal {
  constructor(
    public _modelApiKey: "button_internal",
    public id: string,
    public title: string,
    public page: {
      slug: string;
    }
  ) {}

  static createFromObject(parameters: IButtonInternal): EntityButtonInternal {
    return new EntityButtonInternal(
      parameters._modelApiKey,
      parameters.id,
      parameters.title,
      parameters.page
    );
  }
}
