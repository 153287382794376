import styles from "./ButtonPrimary.module.scss";

import * as React from "react";
import Button, { TButton } from "@components/buttons/Button";
import { cx } from "helpers/classNames";

export enum BUTTON_PRIMARY_VARIANTS {
  Filled = "filled",
  FilledOnPurple = "filledOnPurple"
}

export enum BUTTON_PRIMARY_ARROW {
  Down = "down",
  Up = "up",
  Left = "left",
  Right = "right"
}

type IButtonPrimaryProps = TButton & {
  variant?: BUTTON_PRIMARY_VARIANTS;
  disabled?: boolean;
  arrow?: BUTTON_PRIMARY_ARROW;
};

const getClassName = (type: BUTTON_PRIMARY_VARIANTS | undefined) => {
  switch (type) {
    case BUTTON_PRIMARY_VARIANTS.Filled: {
      return styles.buttonFilled;
    }
    case BUTTON_PRIMARY_VARIANTS.FilledOnPurple: {
      return styles.buttonFilledOnPurple;
    }
    default: {
      return styles.button;
    }
  }
};

const getClassNameArrow = (type: BUTTON_PRIMARY_ARROW | undefined) => {
  switch (type) {
    case BUTTON_PRIMARY_ARROW.Down: {
      return styles.iconDown;
    }
    case BUTTON_PRIMARY_ARROW.Up: {
      return styles.iconUp;
    }
    case BUTTON_PRIMARY_ARROW.Left: {
      return styles.iconLeft;
    }
    default: {
      return styles.icon;
    }
  }
};

const ButtonPrimary = (props: IButtonPrimaryProps) => {
  const { className, children, variant, arrow, ...rest } = props;
  const classNames = cx(getClassName(variant), className);

  return (
    <Button className={classNames} {...rest}>
      <div className={styles.regular}>
        <span>{children}</span>
        {props.arrow && (
          <svg
            className={getClassNameArrow(arrow)}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinejoin="miter"
              d="M18 11H20L24 16L20 21H18L20.808 17.256C20.84 17.2133 20.872 17.1707 20.904 17.128C20.936 17.0853 20.968 17.0427 21 17L7 17.06V14.996H21.064C21.064 14.996 20.9947 14.9107 20.952 14.868C20.92 14.8147 20.8827 14.7613 20.84 14.708L18 11Z"
            />
          </svg>
        )}
      </div>
      <div className={styles.hover}>
        <span>{children}</span>
        {props.arrow && (
          <svg
            className={getClassNameArrow(arrow)}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinejoin="miter"
              d="M18 11H20L24 16L20 21H18L20.808 17.256C20.84 17.2133 20.872 17.1707 20.904 17.128C20.936 17.0853 20.968 17.0427 21 17L7 17.06V14.996H21.064C21.064 14.996 20.9947 14.9107 20.952 14.868C20.92 14.8147 20.8827 14.7613 20.84 14.708L18 11Z"
            />
          </svg>
        )}
      </div>
    </Button>
  );
};

export default ButtonPrimary;
