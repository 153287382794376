import { IReferenceClient } from "@lib/types/IReferenceClient";
import { IBlockTestimonialClient } from "../types/IBlockTestimonialClient";

export class EntityBlockTestimonialClient implements IBlockTestimonialClient {
  constructor(
    public _modelApiKey: "testimonial_client",
    public id: string,
    public eyebrow: string,
    public heading: string,
    public references: IReferenceClient[],
    public isInverted: boolean,
    public isVariantShort: boolean,
    public useAllReferences: boolean,
    public autoplay: boolean
  ) {}

  static createFromObject(parameters: IBlockTestimonialClient): EntityBlockTestimonialClient {
    return new EntityBlockTestimonialClient(
      parameters._modelApiKey,
      parameters.id,
      parameters.eyebrow,
      parameters.heading,
      parameters.references,
      parameters.isInverted,
      parameters.isVariantShort,
      parameters.useAllReferences,
      parameters.autoplay
    );
  }
}
