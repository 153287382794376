import { IButtonHeaderTypeAExternal } from "../types/IButtonHeaderTypeAExternal";

export class EntityButtonHeaderTypeAExternal implements IButtonHeaderTypeAExternal {
  constructor(
    public _modelApiKey: "button_header_type_a_external",
    public id: string,
    public eyebrow: string,
    public title: string,
    public openInNewWindow: boolean,
    public url: string
  ) {}

  static createFromObject(parameters: IButtonHeaderTypeAExternal): EntityButtonHeaderTypeAExternal {
    return new EntityButtonHeaderTypeAExternal(
      parameters._modelApiKey,
      parameters.id,
      parameters.eyebrow,
      parameters.title,
      parameters.openInNewWindow,
      parameters.url
    );
  }
}
