// FIXME: make configurable
const _base = 16;

interface IPxToRem {
  value: number;
  documentFontSize: number;
  base?: number;
}

export const pxToRem = (props: IPxToRem) => {
  const { value, documentFontSize, base } = props;
  return value * (documentFontSize / (base || _base));
};
